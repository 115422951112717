.fds {
  --bol-shade-100: #1a1919;
  --bol-shade-70: #61779e;
  --bol-shade-60: #6c7987;
  --bol-shade-10: #e6e9ec;
  --bol-shade-4: #f3f5f5;
  --bol-shade-2: #f9fafc;
  --bol-chart-blue: #004791;
  --bol-error-100: #983222;
  --bol-text-gain: #537A29;
  --bol-neutral-20: #e9e9ea;
  --bol-link-default: #0a4e90;
  --bol-primary-100: #0a4e90;
  --bol-white: #ffffff;
  /* FDS Widget variables, high level used on all widgets */
  --fds-font-family: var(--font-myriad-pro), ui-sans-serif, system-ui,
    sans-serif;
  /* Backgroiund and text colors for asset class indicators */
  --fds-color-asset-class-bond-b: #2194ff;
  --fds-color-asset-class-bond-c: #000000;
  --fds-color-asset-class-commodity-b: #f1c410;
  --fds-color-asset-class-commodity-c: #000000;
  --fds-color-asset-class-currency-b: #74da3e;
  --fds-color-asset-class-currency-c: #000000;
  --fds-color-asset-class-debt-b: #690078;
  --fds-color-asset-class-debt-c: #ffffff;
  --fds-color-asset-class-etf-b: #4a98db;
  --fds-color-asset-class-etf-c: #000000;
  --fds-color-asset-class-fund-b: #2c618c;
  --fds-color-asset-class-fund-c: #ffffff;
  --fds-color-asset-class-future-b: #94548e;
  --fds-color-asset-class-future-c: #ffffff;
  --fds-color-asset-class-generic-b: #dbdfe4;
  --fds-color-asset-class-generic-c: #000000;
  --fds-color-asset-class-index-b: #d2b4de;
  --fds-color-asset-class-index-c: #000000;
  --fds-color-asset-class-interestRate-b: #546e7a;
  --fds-color-asset-class-interestRate-c: #ffffff;
  --fds-color-asset-class-investmentProduct-b: #00a355;
  --fds-color-asset-class-investmentProduct-c: #000000;
  --fds-color-asset-class-leveragedProduct-b: #dd000f;
  --fds-color-asset-class-leveragedProduct-c: #ffffff;
  --fds-color-asset-class-option-b: #ee7044;
  --fds-color-asset-class-option-c: #000000;
  --fds-color-asset-class-stock-b: #00beff;
  --fds-color-asset-class-stock-c: #000000;
  /* positive change color */
  --fds-color-positive-c: var(--bol-text-gain);
  /* negative change color */
  --fds-color-negative-c: var(--bol-error-100);
  /* positive change color */
  --fds-color-perf-positive-c: var(--bol-text-gain);
  /* negative change color */
  --fds-color-perf-negative-c: var(--bol-error-100);
  --fds-color-base-700: var(--bol-shade-100);
  /* default font size shows up in <th> <td> and <p> */
  --fds-font-size-100: 0.875rem;
  /* default font color + <th> font color */
  --fds-color-base-900: var(--bol-shade-100);
  --fds-color-primary-300: #0000000;
  /* font weight setting */
  --bs-body-font-weight: 400;
  /* font line height setting */
  --bs-body-line-height: 1.5;
  /* for custom up down icon, use a svg mask image. Background color shows through to give it red/green color */
  --ui-icon--trending-negative: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16,18L18.29,15.71L13.41,10.83L9.41,14.83L2,7.41L3.41,6L9.41,12L13.41,8L19.71,14.29L22,12V18H16Z" /></svg>');
  --ui-icon--trending-positive: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16,6L18.29,8.29L13.41,13.17L9.41,9.17L2,16.59L3.41,18L9.41,12L13.41,16L19.71,9.71L22,12V6H16Z" /></svg>');
  /*the following is the variable for the input down arrow icon */
  --ui-icon--chevron-down: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>');
}

fds-chart-roulette.fds {
  /* Chart Roulette Variables */
  /* margin for index head */
  --fds-space-stack-s: 0 0 0.5714rem 0;
  /* separation border for various elements */
  --fds-color-base-400: var(--bol-shade-10);
  /* color of vertical separator between change and %change */
  --fds-color-base-500: var(--bol-shade-10);
}

fds-assets-list.fds {
  /* Assets List Variables */
  /* background color for entire table */
  --fds-color-base-50: #ffffff;
  /* padding for both <th> and <td> */
  --fds-space-inset-squish-default: 1rem 0.5rem;
  /* <th> font size */
  --fds-font-size-100: 0.813rem;
  /* timestamp/label/small font font size */
  --fds-font-size-75: 0.75rem;
  /* bottom border for <tr> */
  --fds-color-base-300: var(--bol-shade-10);
  /* font color for labels in the tbody */
  --fds-color-base-700: var(--bol-shade-70);
  /* real time icon text color */
  --fds-color-base-800: #474c4e;
  /* real time icon background color */
  --fds-color-base-200: #e8eaeb;
}

fds-factsheet-header.fds {
  /* factsheet header */
  /* top level logo, company name gap between items, padding on input in 3rd row */
  --fds-space-inset-m: 0.8571rem;
  /* bottom marging for all rows */
  --fds-space-stack-s: 0 0 0.5714rem 0;
  /* right margin for header second line small text items */
  --fds-space-inline-right-s: 0 0.5714rem 0 0;
  /* 2nd line bottom margin */
  --fds-space-stack-m: 0 0 1.1429rem 0;
  /* 2nd line dl item bottom margin */
  --fds-space-stack-s: 0 0 0.5714rem 0;
  /* 3rd line (price) gap between items */
  --fds-space-inset-xl: 1.4286rem;
  /* bottom row background color border uses base-300 see assets lists above */
  --fds-color-base-100: var(--bol-shade-2);
  /* last row gap between items, large price right margin */
  --fds-space-inset-xs: 0.2857rem;
  /* last row padding */
  --fds-space-inset-m: 0.8571rem;
  /* last row background color */
  /* the bottom row top border is controled with --fds-color-base-300: #dfe1e2; which is above for the table borders */
  --fds-color-base-300: var(--bol-shade-70);
  /* right margin for bottom row items */
  --fds-space-inline-right-l: 0 1.7143rem 0 0;
  --fds-color-base-700: var(--bol-shade-70);
}

fds-company-board-and-management.fds {
  /* company board and management */
  /* top header of widget */
  --fds-font-size-300: 1rem;
  /* chairman of the board font size */
  --fds-font-size-200: 1.125rem;
}

fds-chart-roulette.fds,
fds-time-frame-chart.fds {
  /* Charting variables */
  /* fill, stroke and background color for bar charts */
  --fds-color-chart-1: var(--bol-chart-blue);
  /* text color for x and y axis value plus anything within the chart */
  --fds-color-base-600: var(--bol-shade-60);
  /* chart background color */
  --fds-color-base-50: #ffffff;
  /* y axis line color */
  --fds-color-base-200: var(--bol-shade-10);
  /* font size for y and x axis labels */
  --fds-font-size-50: 0.75rem;
  /* cross hair variable, vertical line when user hovers chart color */
  --fds-color-base-200: #e8eaeb;
  --fds-color-primary-300: #eaf6ff;
}

fds-time-frame-chart.fds {
  --fds-color-primary-500: var(--bol-primary-100);
}

fds-company-key-item.fds {
  /* company key items */
  /* bottom small text color */
  --fds-color-base-600: #717376;
  /* 3rd row price font size */
  --fds-font-size-700: 2.25rem;
  /* 3rd row change and %change font size and input font size */
  --fds-font-size-100: 1rem;
  /* 3rd row input background color */
  --fds-color-base-75: #f9fafa;
}

fds-search-suggester.fds {
  /* search suggestor */
  /* input background */
  --fds-color-base-75: #f9fafa;
  /* input border color */
  --fds-color-base-200: #e8eaeb;
  /* background color of icon */
  --fds-color-base-100: var(--bol-shade-4);
  /* background color for icon mask */
  --fds-color-base-900: #282c2e;
  /* background mask shape for icon */
  --ui-icon--search: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" /></svg>');
}

fds-news-list.fds {
  --fds-color-base-600: var(--bol-shade-70);
  --fds-color-primary-500: var(--bol-shade-70);
  --fds-color-base-700: var(--bol-shade-100);
  --fds-color-base-300: var(--bol-neutral-20);
  --fds-space-inset-s: 1rem;
  --fds-space-inset-xs: 0rem;
}

fds-news-detail.fds {
  --fds-font-size-300: 2rem;
  --fds-color-primary-500: var(--bol-shade-70);
}

fds-calendar-events-list.fds {
  --fds-color-base-600: var(--bol-shade-70);
}

fds-estimates-chart.fds {
  --fds-color-chart-1: var(--bol-chart-blue);
}
